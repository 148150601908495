* {
  box-sizing: border-box;
}

.App {
  font-family: "Hachi Maru Pop", cursive;
  display: flex;
  padding: 15px;
  background: linear-gradient(to right, #c0c0c0, #35a9df); /* Mix of silver and red */
  
}

.App .Meta {
  flex-grow: 1;
  padding-right: 15px;
  
}

.App .Meta input {
  font-family: inherit;
  padding: 5px;
  width: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.App .Meta button {
  padding: 10px 15px;
  border: 2px solid #87ceeb;
  background-color: #33f3fb;
  font-family: inherit;
  margin: 20px 0;
  border-radius: 10px;
  cursor: pointer;
}

.App #downloadWrapper {
  padding: 20px;
}

.App #certificateWrapper {
  box-shadow: 0 0 5px #000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.App #certificateWrapper img {
  display: block;
  width: 1000px;
  height: 800px;
}

.App #certificateWrapper p {
  font-family: "Staatliches", cursive;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
  font-size: 20pt;
  text-align: center;
  width: 100%;
  position: absolute;
}

.App #certificateWrapper_dated p {
  font-family: "Staatliches", cursive;
  margin: 0;
  position: absolute;
  left: 28%;
  /* bottom: 10%; */
  top: 73.4%;
  transform: translateX(-50%);
  font-size: 15pt;
  text-align: center;
  width: 100%;
}
.App #certificateWrapper_S_dated p
{
  font-family: "Staatliches", cursive;
  margin: 0;
  position: absolute;
  left: 35.4%;
  /* bottom: 10%; */
  top: 53.8%;
  transform: translateX(-50%);
  font-size: 15pt;
  text-align: center;
  width: 100%;
}
.App #certificateWrapper_E_dated p
{
  font-family: "Staatliches", cursive;
  margin: 0;
  position: absolute;
  left: 50.5%;
  /* bottom: 10%; */
  top: 53.8%;
  transform: translateX(-50%);
  font-size: 15pt;
  text-align: center;
  width: 100%;
}
.App #certificateWrapper_domain p {
  font-family: "Staatliches", cursive;
  margin: 0;
  position: absolute;
  left: 71%;
  top: 53.7%;
  transform: translateX(-50%);
  font-size: 15pt;
  text-align: center;
  width: 80%;
}
.App .Meta .largeInput {
  white-space: pre-wrap;
  font-family: inherit;
  padding: 40px;
  width: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 20px; /* Increase the font size as desired */
}


