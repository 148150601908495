.left {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: auto;
  height: auto;
  animation: slideshow 15s infinite;

}

@keyframes slideshow {
  0% {
    background-image: url("../src/assets/NEDUET_logo.svg.png");
  }
  33% {
    background-image: url("../src/assets/Ncl_logo.png");
  }
  66% {
    background-image: url("../src/assets/NEDUET_logo.svg.png");
  }
  100% {
    background-image: url("../src/assets/NEDUET_100_Years.png");
  }
}
